
import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'
import { DataTableColumn } from '@/models/DataTableColumn'
import { EventBus } from '@/utils/eventBus'
import { Refund } from '@/models/dto/Refund'
import { TableAction } from '@/models/TableAction'
import { currencyFilter } from '@/utils/string'
import CUCollectionTable from '@/components/CUCollectionTable.vue'
import RefundSidebarDetail from '@/components/RefundSidebarDetail.vue'
import client from '@/services/refund'
import sidebar from '@/store/modules/sidebar'
import auth from '@/store/modules/auth'

@Component({
  components: {
    CUCollectionTable,
  },
})
export default class RefundsList extends mixins(DateMixin) {
  tableView = client.tableView
  tableItemCount = 0
  search = ''

  handleUpdateCount(count: number): void {
    this.tableItemCount = count
  }

  handleSelectedRefund(refund: Refund): void {
    sidebar.push({
      title: 'Refund',
      component: RefundSidebarDetail,
      props: { refund },
    })
  }

  actions: TableAction[] = [
    {
      displayText: 'Void Refund',
      key: 'void-refund',
      icon: 'void',
      isSingleSelectOnly: true,
    },
  ]

  columns: DataTableColumn[] = [
    {
      _t_id: '9578262c-950e-4850-8414-4f9c4277ef6d',
      text: 'ID',
      value: 'refundId',
      type: 'link',
      clickEvent: 'refunds:refund-selected',
    },
    {
      _t_id: '13f7fbbd-0cfe-44f6-93d3-4b2a7a76ce0c',
      text: 'Acc. ID',
      value: 'accountId',
    },
    {
      _t_id: '57f86777-3fcc-4a11-9df9-ff57feda9eea',
      text: 'Company Name',
      value: 'companyName',
    },
    {
      _t_id: '14adb548-38a0-4acf-addd-2a7468399a77',
      text: 'Amount',
      value: 'amount',
      computedText: (row: Refund): string => currencyFilter(row.amount),
    },
    {
      _t_id: '16243d7b-ec42-48a2-bfcc-b9b5175b6d7c',
      text: 'Refund Type',
      value: 'refundType',
    },
    {
      _t_id: '7adc8533-612d-45ef-b815-3ea6b73006ef',
      text: 'Created On',
      value: 'createdOn',
      computedText: (row: Refund): string =>
        this.formatMediumDate(row.createdOn, { tz: auth.getUserTimeZone }),
    },
    {
      _t_id: 'efeabc05-318f-474b-967f-b82fd58d27d9',
      text: 'Created By',
      value: 'createdBy',
    },
    {
      _t_id: '41a4fc58-ccd2-4a1c-bfcd-09f48f61016d',
      text: 'Void',
      value: 'voidFlag',
      computedText: (row: Refund): string => (row.voidFlag ? 'Yes' : 'No'),
    },
    {
      _t_id: '73a5424b-b449-4b2d-8e5d-18dd1cf2406f',
      text: 'Receipt ID',
      value: 'receiptId',
    },
  ]

  mounted(): void {
    EventBus.$on('refunds:refund-selected', this.handleSelectedRefund)
  }

  beforeDestory(): void {
    EventBus.$off('refunds:refund-selected', this.handleSelectedRefund)
  }
}
